import request from '@/utils/request'


export function goodsSta(query) {
  return request({
    url: '/statistics/goods/goods/page',
    method: 'get',
    params: query
  })
}

export function goodsSales(query) {
  return request({
    url: '/statistics/goods/goods/sales',
    method: 'get',
    params: query
  })
}

export function goodsCart(query) {
  return request({
    url: '/statistics/goods/goods/cart',
    method: 'get',
    params: query
  })
}
export function cateSta(query) {
  return request({
    url: '/statistics/goods/cate/page',
    method: 'get',
    params: query
  })
}

export function skuSta(query) {
  return request({
    url: '/statistics/goods/sku/page',
    method: 'get',
    params: query
  })
}


export function brandSta(query) {
  return request({
    url: '/statistics/goods/brand/page',
    method: 'get',
    params: query
  })
}
